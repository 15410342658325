import React, { useEffect, useState } from "react";
import "./style/greeting.css";
import CheckBox from "../Components/CheckBox";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { ServerApi } from "../api/ServerApi";
import { Modal } from "react-bootstrap";
import refreshIcon from "../resourse/icons/refreshColor.svg";
import CryptoJS from "crypto-js";
import axios from "axios";
import packageJson from '../../package.json'

export default function GreetingsPage() {
  const serverApi = new ServerApi();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleStatus, setVisibleStatus] = useState("d-block");
  const [currIp ,setCurrIp]=useState('')
  const [ip,setIp] = useState('')
  const [cookies, setCookie, removeCookie] = useCookies([
    "operator_id",
    "user_id",
    "esia_url",
    "esia_token",
    "link_token",
  ]);
  const [operator_id, setOperatorId] = useState(searchParams.get("c"))
  const [user_id, setUserId] = useState(searchParams.get("u"))
  const [modalOpen, setModalOpen] = useState(false);


  const handleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);
    setIsDisabled(!isDisabled);
  };
  const handleButtonClick = () => {
    if (!isDisabled) {
      navigate("/view");
    }
  };

  useEffect(() => {
    console.log(operator_id, user_id)
    //Не переданы данные в ссылке
    if (!operator_id || !user_id) {
      setVisibleStatus("d-none");
      // Разбор "короткого url"
      let currentURL = window.location.href
      currentURL = currentURL.replace('http://localhost:3000', 'https://app.idgos.ru')
      if(currentURL.includes('/s/')) {
        serverApi.encryptShortUrl(currentURL).then((response) => {
          console.log(response)
          if (response.status === 200) {
            if (response.data.success) {
              let q = response.data.data.original_url.replace('https://app.idgos.ru/?', '')
              let q_arr = q.split('&')
              setOperatorId(q_arr[1].replace('c=', ''))
              setUserId(q_arr[0].replace('u=', ''))
              document.getElementById("disable_link").classList.add("d-none");
            }
            else {
              document.getElementById("disable_link").classList.remove("d-none");
              document.getElementById("disable_link").textContent = 'Пользователь не найден'
            }
          }
        })
      }
    } else {
      setVisibleStatus("d-block")
      //Проверка разрешения на вход по ссылке. Проверяется временная метка. Действительна сутки
      const link_date = Date.now() / 1000;
      const body = {
        action: "getLinkActiveDate",
        data: {
          user_id: user_id,
          operator_id: operator_id,
          link_date: link_date,
        },
      };
      serverApi.sendPostRequest(body).then((response) => {
        if (response.status === 200) {
          if (!response.data.success) {
            document.getElementById("disable_link").classList.remove("d-none");
            setVisibleStatus("d-none");
            localStorage.clear();
          }
          else {
            operratorInfo()
          }
        }
      });
    }
    // localStorage.clear()
  }, [operator_id, user_id]);


  const operratorInfo = () => {
    localStorage.setItem("operator_id", operator_id);
    localStorage.setItem("user_id", user_id);
      //Название компании
      serverApi.getOperatorInfo(operator_id).then((response) => {
        if (response.status == 200) {
          localStorage.setItem("operator_name", response.data.name);
          localStorage.setItem("phone", response.data.phone);
          localStorage.setItem("site", response.data.site);
        }
      });
  }


  useEffect(()=>{

    setIp('')
    setCurrIp('RU');
    // getCountryByIP()
    // const fetchData = async () => {
    //   try {
    //     const ipResponse = await axios.get('https://api.ipify.org?format=json');
    //     console.log(ipResponse?.data?.ip)
    //     getCountryByIP()
    //     // const ipResponse = await axios.get('http://ip-api.com/json'); 
    //     // const countryResponse = await axios.get(`https://ipapi.co/${ipResponse?.data?.ip}/json/`);
    //     // // console.log(ipResponse);
    //     // setIp(ipResponse?.data?.ip)
    //     // setCurrIp(countryResponse?.data?.country_code);
    //   } catch (error) {
    //     console.error('Произошла ошибка при получении данных:', error);
    //     // window.location.reload();
    //     setCurrIp('Поризошла ошибка'); // или любое другое значение по умолчанию
    //   }
    // };
  
    // fetchData();
  },[])

  async function getCountryByIP() {
    try {
        const response = await fetch('http://ip-api.com/json/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        if (data.status === 'fail') {
          // window.location.reload();
          setCurrIp('Поризошла ошибка'); // или любое другое значение по умолчанию
        } else {
          setIp(data.query)
          setCurrIp(data.countryCode);
        }
    } catch (error) {
      console.error('Произошла ошибка при получении данных:', error);
      // window.location.reload();
      setCurrIp('Поризошла ошибка'); // или любое другое значение по умолчанию
    }
}
 
  return (
    <div className="main-greet">
      <div id="greeting">
        <div id="greeting-block" className="mx-2">
          <div className="greeting-img-block">
            <img
              src="../icons/logo-idgos.png"
              alt="!#"
              className="logo-idgos"
            />
          </div>
          <div className="greeting-text-block">
            <h1 className="greeting-title">Добро пожаловать IDGOS</h1>
            <p className="greeting-subtitle">
              Сервис для подписания документов через ГосУслуги
            </p>
            <p
              className="greeting-subtitle d-none"
              id="disable_link"
              style={{ color: "red", fontWeight: "bold" }}
            >
              Ваша ссылка просрочена
            </p>
          </div>
          {/* <div className='greeting-input-block'>
                <input 
                    className='greeting-input'
                    type="checkbox" 
                    checked={isChecked} 
                    onChange={handleCheckboxChange}
                />
                <span className='greeting-input-text'>Я согласен с политикой конфиденциальности</span>
            </div> */}
          {currIp == 'RU' || currIp == 'UA' ?
          <>
          <div className={visibleStatus}>
            <CheckBox
              label="Я согласен с политикой конфиденциальности"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </div>

          <div className={`greeting-btn-block ${visibleStatus}`}>
            <button
              onClick={handleButtonClick}
              disabled={isDisabled}
              style={{
                backgroundColor: isDisabled
                  ? "rgb(56 141 168 / 28%)"
                  : "#388DA8",
                color: isDisabled ? "#666" : "#fff",
                border: "none",
                borderRadius: "12px",
                padding: "14px 32px 14px 32px",
                cursor: isDisabled ? "not-allowed" : "pointer",
              }}
            >
              Продолжить
            </button>
          </div>
          </> :
          currIp == '' ? <img style={{width:'30px'}} src="../icons/VAyR.gif"/>:
          currIp == 'Поризошла ошибка' ? 
          <>
            <p style={{textAlign:'center'}}>Произошла ошибка , попробуйте перезагрузить страницу</p>
          </>
          :
          <>
            <p style={{textAlign:'center',fontWeight: 'bold'}}>Внимание!</p>
            <p style={{textAlign:'center'}}>Нет возможности доступа.</p>
            <p style={{textAlign:'center'}}>Ваш IP адрес принадлежит другой стране.</p>
            <p style={{textAlign:'center'}}>Отключите VPN или вернитесь в матушку Россию)</p>
            <p style={{textAlign:'center'}}>IP адрес: {currIp}{' '}{ip}</p>
          </>
          }
        </div>
        
      </div>
      <div className="app-version">версия {packageJson.version}</div>
    </div>
  );
}
